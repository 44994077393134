





























import { defineComponent, ref, PropType } from '@nuxtjs/composition-api';
import CtaPartial from '@theme/components/molecules/CtaPartial.vue';
import { CtaInterface, ImageHolderInterface, RichTextItemInterface } from '@amplience/types';
import { breakpoints } from '~/utils/breakpoints';

export default defineComponent({
  name: 'Error404',
  components: {
    CtaPartial,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    richText: {
      type: Array as PropType<RichTextItemInterface[]>,
      default: () => [],
    },
    cta: {
      type: Object,
      default: () => ({}),
    },
    errorImage: {
      type: Object as PropType<ImageHolderInterface>,
      default: () => ({}),
    },
  },
  setup(props) {
    const ctaParams = ref<CtaInterface>({});

    const isCallToAction = (): boolean => {
      return !!(props.cta?.url && props.cta?.type && props.cta?.title);
    }

    ctaParams.value = {
      linkType: props.cta.linkType,
      title: props.cta.title || '',
      url: props.cta.url || '',
      type: props.cta.type || 'Primary',
      newWindow: !!(props.cta.newWindow),
      noFollow: !!(props.cta.noFollow),
      trackingQuery: props.cta.trackingQuery || '',
    };

    return {
      ctaParams,
      isCallToAction,
      sources: [
        {
          aspect: '1.37:1',
          height: 251,
          media: `(min-width: ${breakpoints.desktop}px)`,
        },
        {
          aspect: '1.37:1',
          height: 344,
          media: '(min-width: 768px)',
        },
        {
          aspect: '1.37:1',
          height: 280,
          media: '(max-width: 767px)',
        },
      ],
    }
  },
})
